/**
--------------------------------------------------------------------------------------
This set of fonts are used in this project under the license: (.....)
------------------------------------------------------------------------------------- fonts/-
*	
**/

@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 10;
  src: url("assets/fonts/dana/eot/dana-noen-thin.eot");
  src: url("assets/fonts/dana/eot/dana-noen-thin.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/dana/woff2/dana-noen-thin.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/dana/woff/dana-noen-thin.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 200;
  src: url("assets/fonts/dana/eot/dana-noen-extralight.eot");
  src: url("assets/fonts/dana/eot/dana-noen-extralight.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/dana/woff2/dana-noen-extralight.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/dana/woff/dana-noen-extralight.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 300;
  src: url("assets/fonts/dana/eot/dana-noen-light.eot");
  src: url("assets/fonts/dana/eot/dana-noen-light.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/dana/woff2/dana-noen-light.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/dana/woff/dana-noen-light.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 500;
  src: url("assets/fonts/dana/eot/dana-noen-medium.eot");
  src: url("assets/fonts/dana/eot/dana-noen-medium.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/dana/woff2/dana-noen-medium.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/dana/woff/dana-noen-medium.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 600;
  src: url("assets/fonts/dana/eot/dana-noen-demibold.eot");
  src: url("assets/fonts/dana/eot/dana-noen-demibold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/dana/woff2/dana-noen-demibold.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/dana/woff/dana-noen-demibold.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 750;
  src: url("assets/fonts/dana/eot/dana-noen-ultrabold.eot");
  src: url("assets/fonts/dana/eot/dana-noen-ultrabold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/dana/woff2/dana-noen-ultrabold.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/dana/woff/dana-noen-ultrabold.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 800;
  src: url("assets/fonts/dana/eot/dana-noen-extrabold.eot");
  src: url("assets/fonts/dana/eot/dana-noen-extrabold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/dana/woff2/dana-noen-extrabold.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/dana/woff/dana-noen-extrabold.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 900;
  src: url("assets/fonts/dana/eot/dana-noen-black.eot");
  src: url("assets/fonts/dana/eot/dana-noen-black.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/dana/woff2/dana-noen-black.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/dana/woff/dana-noen-black.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: bold;
  src: url("assets/fonts/dana/eot/dana-noen-bold.eot");
  src: url("assets/fonts/dana/eot/dana-noen-bold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/dana/woff2/dana-noen-bold.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/dana/woff/dana-noen-bold.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/dana/eot/dana-noen-regular.eot");
  src: url("assets/fonts/dana/eot/dana-noen-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/dana/woff2/dana-noen-regular.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/dana/woff/dana-noen-regular.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
/**
Iranic fonts	
**/

@font-face {
  font-family: dana;
  font-style: italic;
  font-weight: 10;
  src: url("assets/fonts/dana/eot/dana-noen-thinitalic.eot");
  src: url("assets/fonts/dana/eot/dana-noen-thinitalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/dana/woff2/dana-noen-thinitalic.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/dana/woff/dana-noen-thinitalic.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: italic;
  font-weight: 200;
  src: url("assets/fonts/dana/eot/dana-noen-extralightitalic.eot");
  src: url("assets/fonts/dana/eot/dana-noen-extralightitalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/dana/woff2/dana-noen-extralightitalic.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/dana/woff/dana-noen-extralightitalic.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: italic;
  font-weight: 300;
  src: url("assets/fonts/dana/eot/dana-noen-lightitalic.eot");
  src: url("assets/fonts/dana/eot/dana-noen-lightitalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/dana/woff2/dana-noen-lightitalic.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/dana/woff/dana-noen-lightitalic.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: italic;
  font-weight: 500;
  src: url("assets/fonts/dana/eot/dana-noen-mediumitalic.eot");
  src: url("assets/fonts/dana/eot/dana-noen-mediumitalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/dana/woff2/dana-noen-mediumitalic.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/dana/woff/dana-noen-mediumitalic.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: italic;
  font-weight: 600;
  src: url("assets/fonts/dana/eot/dana-noen-demibolditalic.eot");
  src: url("assets/fonts/dana/eot/dana-noen-demibolditalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/dana/woff2/dana-noen-demibolditalic.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/dana/woff/dana-noen-demibolditalic.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: italic;
  font-weight: 750;
  src: url("assets/fonts/dana/eot/dana-noen-ultrabolditalic.eot");
  src: url("assets/fonts/dana/eot/dana-noen-ultrabolditalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/dana/woff2/dana-noen-ultrabolditalic.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/dana/woff/dana-noen-ultrabolditalic.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: italic;
  font-weight: 800;
  src: url("assets/fonts/dana/eot/dana-noen-extrabolditalic.eot");
  src: url("assets/fonts/dana/eot/dana-noen-extrabolditalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/dana/woff2/dana-noen-extrabolditalic.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/dana/woff/dana-noen-extrabolditalic.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: italic;
  font-weight: 900;
  src: url("assets/fonts/dana/eot/dana-noen-blackitalic.eot");
  src: url("assets/fonts/dana/eot/dana-noen-blackitalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/dana/woff2/dana-noen-blackitalic.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/dana/woff/dana-noen-blackitalic.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: italic;
  font-weight: bold;
  src: url("assets/fonts/dana/eot/dana-noen-bolditalic.eot");
  src: url("assets/fonts/dana/eot/dana-noen-bolditalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/dana/woff2/dana-noen-bolditalic.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/dana/woff/dana-noen-bolditalic.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: italic;
  font-weight: normal;
  src: url("assets/fonts/dana/eot/dana-noen-regularitalic.eot");
  src: url("assets/fonts/dana/eot/dana-noen-regularitalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("assets/fonts/dana/woff2/dana-noen-regularitalic.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("assets/fonts/dana/woff/dana-noen-regularitalic.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
/*
IRAN Sans-serif fonts are considered a proprietary software. To gain information about the laws regarding the use of these fonts, please visit www.fontiran.com
--------------------------------------------------------------------------------------
This set of fonts are used in this project under the license: (SYRSQ)
--------------------------------------------------------------------------------------
*/
@font-face {
  font-family: IRANYekan;
  font-style: normal;
  font-weight: 700;
  src: url("assets/fonts/IRANYekan/eot/iranyekanwebbold.eot");
  src: url("assets/fonts/IRANYekan/eot/iranyekanwebbold.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/IRANYekan/woff2/iranyekanwebbold.woff2") format("woff2"),
    url("assets/fonts/IRANYekan/woff/iranyekanwebbold.woff") format("woff"),
    url("assets/fonts/IRANYekan/ttf/iranyekanwebbold.ttf") format("truetype");
}
@font-face {
  font-family: IRANYekan;
  font-style: normal;
  font-weight: 300;
  src: url("assets/fonts/IRANYekan/eot/iranyekanweblight.eot");
  src: url("assets/fonts/IRANYekan/eot/iranyekanweblight.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/IRANYekan/woff2/iranyekanweblight.woff2") format("woff2"),
    url("assets/fonts/IRANYekan/woff/iranyekanweblight.woff") format("woff"),
    url("assets/fonts/IRANYekan/ttf/iranyekanweblight.ttf") format("truetype");
}
@font-face {
  font-family: IRANYekan;
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/IRANYekan/eot/iranyekanwebregular.eot");
  src: url("assets/fonts/IRANYekan/eot/iranyekanwebregular.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/IRANYekan/woff2/iranyekanwebregular.woff2")
      format("woff2"),
    url("assets/fonts/IRANYekan/woff/iranyekanwebregular.woff") format("woff"),
    url("assets/fonts/IRANYekan/ttf/iranyekanwebregular.ttf") format("truetype");
}
/* IRANSans */
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 900;
  src: url("assets/fonts/IRANSans/eot/IRANSansWeb(NoEn)_Black.eot");
  src: url("assets/fonts/IRANSans/eot/IRANSansWeb(NoEn)_Black.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/IRANSans/woff2/IRANSansWeb(NoEn)_Black.woff2")
      format("woff2"),
    url("assets/fonts/IRANSans/woff/IRANSansWeb(NoEn)_Black.woff")
      format("woff"),
    url("assets/fonts/IRANSans/ttf/IRANSansWeb(NoEn)_Black.ttf")
      format("truetype");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 700;
  src: url("assets/fonts/IRANSans/eot/IRANSansWeb(NoEn)_Bold.eot");
  src: url("assets/fonts/IRANSans/eot/IRANSansWeb(NoEn)_Bold.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/IRANSans/woff2/IRANSansWeb(NoEn)_Bold.woff2")
      format("woff2"),
    url("assets/fonts/IRANSans/woff/IRANSansWeb(NoEn)_Bold.woff") format("woff"),
    url("assets/fonts/IRANSans/ttf/IRANSansWeb(NoEn)_Bold.ttf")
      format("truetype");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  src: url("assets/fonts/IRANSans/eot/IRANSansWeb(NoEn)_Medium.eot");
  src: url("assets/fonts/IRANSans/eot/IRANSansWeb(NoEn)_Medium.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/IRANSans/woff2/IRANSansWeb(NoEn)_Medium.woff2")
      format("woff2"),
    url("assets/fonts/IRANSans/woff/IRANSansWeb(NoEn)_Medium.woff")
      format("woff"),
    url("assets/fonts/IRANSans/ttf/IRANSansWeb(NoEn)_Medium.ttf")
      format("truetype");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  src: url("assets/fonts/IRANSans/eot/IRANSansWeb(NoEn)_Light.eot");
  src: url("assets/fonts/IRANSans/eot/IRANSansWeb(NoEn)_Light.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/IRANSans/woff2/IRANSansWeb(NoEn)_Light.woff2")
      format("woff2"),
    url("assets/fonts/IRANSans/woff/IRANSansWeb(NoEn)_Light.woff")
      format("woff"),
    url("assets/fonts/IRANSans/ttf/IRANSansWeb(NoEn)_Light.ttf")
      format("truetype");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 200;
  src: url("assets/fonts/IRANSans/eot/IRANSansWeb(NoEn)_UltraLight.eot");
  src: url("assets/fonts/IRANSans/eot/IRANSansWeb(NoEn)_UltraLight.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/IRANSans/woff2/IRANSansWeb(NoEn)_UltraLight.woff2")
      format("woff2"),
    url("assets/fonts/IRANSans/woff/IRANSansWeb(NoEn)_UltraLight.woff")
      format("woff"),
    url("assets/fonts/IRANSans/ttf/IRANSansWeb(NoEn)_UltraLight.ttf")
      format("truetype");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/IRANSans/eot/IRANSansWeb(NoEn).eot");
  src: url("assets/fonts/IRANSans/eot/IRANSansWeb(NoEn).eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/IRANSans/woff2/IRANSansWeb(NoEn).woff2") format("woff2"),
    url("assets/fonts/IRANSans/woff/IRANSansWeb(NoEn).woff") format("woff"),
    url("assets/fonts/IRANSans/ttf/IRANSansWeb(NoEn).ttf") format("truetype");
}
@font-face {
  font-family: Lalezar;
  font-style: normal;
  font-weight: 200;
  src: url("assets/fonts/Lalezar/Lalezar-Regular.ttf");
}

@font-face {
  font-family: "IRANYekanXVF";
  src: url("assets/fonts/IRANYekanXVF.woff") format("woff-variations"),
    /* will be the standard and works in Safari now */
      url("assets/fonts/IRANYekanXVF.woff") format("woff");
  /* for the other supporting browsers */
  font-weight: 100 1000;
  font-display: fallback;
}

@font-face {
  font-family: IRANYekanX;
  src: url("assets/fonts/IRANYekanX-regular.woff") format("woff");
}
@font-face {
  font-family: IRANYekanX;
  src: url("assets/fonts/IRANYekanX-bold.woff") format("woff");
  font-weight: 700;
}

/* public */
body,
html {
  margin: 0;
  font-family: dana, IRANYekan, IRANSans, "Nunito", "Open Sans", Lato,
    -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Helvetica Neue", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  overflow-x: hidden;
  line-height: normal;
}

.ltr {
  direction: ltr;
  font-family: Poppins, "Nunito", "Open Sans", Lato, dana, IRANSans, IRANYekan,
    -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Helvetica Neue", sans-serif;
}
.rtl {
  direction: rtl;
}
.font-family-OpenSans {
  font-family: "Open Sans", Poppins, "Nunito" Lato, IRANSans, IRANYekan,
    -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Helvetica Neue", sans-serif;
}
.font-family-IRANSans {
  font-family: IRANSans, IRANYekan, Poppins, "Nunito", "Open Sans", Lato,
    -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Helvetica Neue", sans-serif;
}
.font-family-IRANYekan {
  font-family: IRANYekan, IRANSans, Poppins, "Nunito", "Open Sans", Lato,
    -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Helvetica Neue", sans-serif;
}
.font-family-Lalezar {
  font-family: Lalezar, IRANYekan, IRANSans, Poppins, "Nunito", "Open Sans",
    Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Helvetica Neue", sans-serif;
}
.font-family-dana {
  font-family: dana, IRANYekan, IRANSans, "Nunito", "Open Sans", Lato,
    -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Helvetica Neue", sans-serif;
}
