@tailwind base;
@tailwind components;
@tailwind utilities;
@import "assets/css/typography";
@import "assets/css/message-card";

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.register-form {
  .wave {
    opacity: 0.4;
    position: absolute;
    top: 3%;
    left: 50%;
    background: #0af;
    width: 500px;
    height: 500px;
    margin-left: -250px;
    margin-top: -250px;
    -webkit-transform-origin: 50% 48%;
    transform-origin: 50% 48%;
    border-radius: 43%;
    -webkit-animation: drift 3000ms infinite linear;
    animation: drift 3000ms infinite linear;
  }

  .wave.-three {
    -webkit-animation: drift 5000ms infinite linear;
    animation: drift 5000ms infinite linear;
  }

  .wave.-two {
    -webkit-animation: drift 7000ms infinite linear;
    animation: drift 7000ms infinite linear;
    opacity: 0.1;
    background: yellow;
  }

  .title {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    line-height: 300px;
    text-align: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    color: white;
    text-transform: uppercase;
    font-family: "SF", serif;
    font-weight: bold;
    letter-spacing: 0.2em;
    font-size: 36px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    text-indent: 0.3em;
  }

  @-webkit-keyframes drift {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    from {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes drift {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    from {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

.owl {
  margin: auto;
  width: 211px;
  height: 108px;
  background-image: url("./assets/img/owl-login.png");
  position: relative;
}

.owl .hand {
  width: 34px;
  height: 34px;
  border-radius: 40px;
  background-color: #472d20;
  transform: scaleY(0.6);
  position: absolute;
  left: 14px;
  bottom: -8px;
  transition: 0.3s ease-out;
}

.owl .hand.password {
  transform: translateX(42px) translateY(-15px) scale(0.7);
}

.owl .hand.hand-r {
  left: 170px;
}

.owl .hand.hand-r.password {
  transform: translateX(-42px) translateY(-15px) scale(0.7);
}

.owl .arms {
  position: absolute;
  top: 58px;
  height: 41px;
  width: 100%;
  overflow: hidden;
}

.owl .arms .arm {
  width: 40px;
  height: 65px;
  background-image: url("./assets/img/owl-login-arm.png");
  position: absolute;
  left: 20px;
  top: 40px;
  transition: 0.3s ease-out;
}

.owl .arms .arm.password {
  transform: translateX(40px) translateY(-40px);
}

.owl .arms .arm.arm-r {
  left: 158px;
  transform: scaleX(-1);
}

.owl .arms .arm.arm-r.password {
  transform: translateX(-40px) translateY(-40px) scaleX(-1);
}

/* password validation*/

.password-validation {
  direction: rtl;
  max-height: 0;
  overflow: hidden;
  text-align: right;
  transition: max-height 0.3s ease-out;
}
.password-validation .progress {
  height: 0.7rem;
  border-radius: 0.1rem;
}

.password-validation svg {
  margin-left: 5px;
}

.password-validation h4 {
  font-weight: 500;
}
.password-validation span {
  display: block;
  font-size: 0.9rem;
  padding: 1px 5px;
  font-weight: 500;
  transition: all 0.2s;
}

.password-validation span.valid svg {
  animation: lookAtMe 1s;
}
@keyframes lookAtMe {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}
.password-validation p.comment {
  display: block;
  font-size: 0.8rem;
  padding: 5px;
}

.password-validation span.invalid {
  font-weight: 400;
  color: #ccc;
}

.password-validation span.valid {
  color: #27ae60;
}

.password-validation.show {
  padding: 15px 5px;
}

/* start popup svg text */
.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  direction: rtl;
  max-width: 70%;
  background-color: white;
  border: 1px solid #c80000;
  border-radius: 13px;
  padding: 4px 10px 4px 24px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 8px;
  pointer-events: none;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: right;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}
.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}
.alert-validate.ltr::before {
  padding: 4px 24px 4px 10px;
  right: 8px;
  left: unset;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
