@keyframes bounce {
  50% {
    transform: translateY(-10px);
  }
}
@keyframes scale {
  50% {
    transform: scale(0.9);
  }
}
@keyframes roll {
  0% {
    transform: rotate(0deg);
    left: 25%;
  }
  50% {
    left: 60%;
  }
  100% {
    transform: rotate(360deg);
    left: 25%;
  }
}
@keyframes move {
  0% {
    left: 25%;
  }
  50% {
    left: 60%;
  }
  100% {
    left: 25%;
  }
}
.message-card .success-box {
  position: relative;
  width: 300px;
  height: 300px;
  background: linear-gradient(to bottom right, #b0db7d 40%, #99dbb4 100%);
  border-radius: 20px;
  perspective: 40px;
}
.message-card .error-box {
  position: relative;
  width: 300px;
  height: 300px;
  background: linear-gradient(to bottom left, #ef8d9c 40%, #ffc39e 100%);
  border-radius: 20px;
}
.message-card .dot {
  width: 8px;
  height: 8px;
  background: #fcfcfc;
  border-radius: 50%;
  position: absolute;
  top: 4%;
  right: 6%;
}
.message-card .dot:hover {
  background: #c9c9c9;
}
.message-card .two {
  right: 12%;
  opacity: 0.5;
}
.message-card .face {
  position: absolute;
  width: 22%;
  height: 22%;
  background: #fcfcfc;
  border-radius: 50%;
  border: 1px solid #777777;
  top: 21%;
  left: 37.5%;
  z-index: 2;
  animation: bounce 1s ease-in infinite;
}
.message-card .face2 {
  position: absolute;
  width: 22%;
  height: 22%;
  background: #fcfcfc;
  border-radius: 50%;
  border: 1px solid #777777;
  top: 21%;
  left: 37.5%;
  z-index: 2;
  animation: roll 3s ease-in-out infinite;
}
.message-card .eye {
  position: absolute;
  width: 5px;
  height: 5px;
  background: #777777;
  border-radius: 50%;
  top: 40%;
  left: 20%;
}
.message-card .right {
  left: 68%;
}
.message-card .mouth {
  position: absolute;
  top: 43%;
  left: 41%;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
.message-card .happy {
  border: 2px solid;
  border-color: transparent #777777 #777777 transparent;
  transform: rotate(45deg);
}
.message-card .sad {
  top: 49%;
  border: 2px solid;
  border-color: #777777 transparent transparent #777777;
  transform: rotate(45deg);
}
.message-card .shadow {
  position: absolute;
  width: 21%;
  height: 3%;
  opacity: 0.5;
  background: #777777;
  left: 40%;
  top: 43%;
  border-radius: 50%;
  z-index: 1;
}
.message-card .move {
  animation: move 3s ease-in-out infinite;
}
.message-card .scale {
  animation: scale 1s ease-in infinite;
}
.message-card .message {
  position: absolute;
  width: 100%;
  text-align: center;
  height: 40%;
  top: 50%;
  font-size: 0.9em;
}
.message-card .button-box {
  position: absolute;
  background: #fcfcfc;
  width: 50%;
  height: 15%;
  border-radius: 20px;
  top: 77%;
  left: 25%;
  outline: 0;
  border: none;
  box-shadow: 2px 2px 10px rgba(119; 119; 119; 0.5);
  transition: all 0.5s ease-in-out;
}
.message-card .button-box:hover {
  background: #efefef;
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}
